// Redux business units feature slice to store all global user state related data.

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IHookmanState } from 'interfaces'
import { RootState } from 'store'

const initialState: IHookmanState = {
  filters: {
    certifiedLevel: [],
    workingLevel: [],
    targetLevel: [],
  },
  jobRoles: [],
  searchString: '',
  table: {
    page: 1,
    pageSize: 10,
  },
}

const coreTechnicalHeadListSlice = createSlice({
  name: 'coreTechnicalHeadListSlice',
  initialState: initialState,
  reducers: {
    resetCoreTechnicalHeadFilters: () => initialState,
    setCoreTechnicalHeadFilters(
      state,
      action: PayloadAction<{
        filter: keyof IHookmanState['filters']
        value: string
      }>,
    ) {
      const { filter, value } = action.payload
      const filterValue = state.filters[filter]
      if (filterValue) {
        if (filterValue.includes(value)) {
          state.filters[filter] = filterValue.filter(item => item !== value)
        } else {
          state.filters[filter] = [...filterValue, value]
        }
      } else {
        state.filters[filter] = [value]
      }
    },
    setCoreTechnicalHeadJobRole(state, action: PayloadAction<string[]>) {
      state.jobRoles = action.payload
    },
    setCoreTechnicalHeadSearchString(state, action: PayloadAction<string>) {
      state.searchString = action.payload
    },
    setCoreTechnicalHeadTableConfig(
      state,
      action: PayloadAction<IHookmanState['table']>,
    ) {
      const { page, pageSize } = action.payload
      state.table.page = page
      state.table.pageSize = pageSize
    },
  },
})

export const {
  setCoreTechnicalHeadFilters,
  setCoreTechnicalHeadJobRole,
  setCoreTechnicalHeadSearchString,
  resetCoreTechnicalHeadFilters,
  setCoreTechnicalHeadTableConfig,
} = coreTechnicalHeadListSlice.actions

export const selectCoreTechnicalHeadTableConfig = (state: RootState) =>
  state.coreTechnicalHead.table
export const selectCoreTechnicalHeadSelectedFilters = (state: RootState) =>
  state.coreTechnicalHead.filters
export const selectCoreTechnicalHeadSelectedJobRole = (state: RootState) =>
  state.coreTechnicalHead.jobRoles
export const selecCoreTechnicalHeadSelectedSearchString = (state: RootState) =>
  state.coreTechnicalHead.searchString

export default coreTechnicalHeadListSlice.reducer
