import { Grid, TextField } from '@mui/material'
import searchIcon from 'assets/svgs/icon_search.svg'
import { useIntl } from 'react-intl'
import {
  selecSupportHeadSelectedSearchString,
  setSupportHeadSearchString,
  useAppDispatch,
  useAppSelector,
} from 'store'

const SearchInput = () => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const selectedSearchString = useAppSelector(selecSupportHeadSelectedSearchString)

  return (
    <Grid item xs={12} md={9}>
      <TextField
        id="search"
        placeholder={intl.formatMessage({
          id: 'filters.searchStaff',
        })}
        variant="outlined"
        size="small"
        fullWidth
        value={selectedSearchString || ''}
        onChange={e => {
          dispatch(setSupportHeadSearchString(e.target.value))
        }}
        InputProps={{
          startAdornment: (
            <img
              src={searchIcon}
              alt="Search Staff"
              width="15"
              style={{ marginRight: '10px' }}
            />
          ),
          sx(theme) {
            return {
              borderRadius: '0px',
              'input::placeholder': {
                color: theme.palette.primary.main,
                opacity: 1,
              },
              'input ~ fieldset': {
                borderWidth: '1px !important',
              },
            }
          },
        }}
      />
    </Grid>
  )
}

export default SearchInput
