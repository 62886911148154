import { Box, Collapse, Grid, useMediaQuery, useTheme } from '@mui/material'
import Button from 'components/common/Button'
import { FC, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { selectSelectedBusinessUnits, useAppDispatch, useAppSelector } from 'store'
import { useGetMyStaffConfigQueryQuery } from 'store/api'
import {
  resetCoreTechnicalHeadFilters,
  selectCoreTechnicalHeadSelectedFilters,
  selectCoreTechnicalHeadSelectedJobRole,
  selecCoreTechnicalHeadSelectedSearchString,
  selectCoreTechnicalHeadTableConfig,
  setCoreTechnicalHeadTableConfig,
} from 'store/slices'
import SettingsIcon from 'components/common/SettingsIcon'
import JobRoles from './components/JobRoles'
import LevelsActions from './components/LevelsActions'
import SearchInput from './components/SearchInput'
import {
  StyledBoxWrapper,
  StyledContainer,
  StyledMobileContainer,
  StyledStack,
} from 'components/StaffMembers/Filters/styles'

interface FiltersProps {
  setShouldRefetch: React.Dispatch<React.SetStateAction<boolean>>
  shouldRefetch: boolean
}

const Filters: FC<FiltersProps> = ({ setShouldRefetch, shouldRefetch }) => {
  const dispatch = useAppDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const { pageSize } = useAppSelector(selectCoreTechnicalHeadTableConfig)
  const selectedFilters = useAppSelector(selectCoreTechnicalHeadSelectedFilters)
  const selectedJobRole = useAppSelector(selectCoreTechnicalHeadSelectedJobRole)
  const selectedSearchString = useAppSelector(selecCoreTechnicalHeadSelectedSearchString)
  const selectedBusinessUnits = useAppSelector(selectSelectedBusinessUnits)
  const theme = useTheme()
  const isAboveSmall = useMediaQuery(theme.breakpoints.up('sm'))
  const [allValueFilters, setAllValueFilters] = useState(0)
  const totalSelectedFilters = Object.values(selectedFilters).reduce(
    (acc, curr) => acc + curr.length,
    0,
  )
  const totalSelectedJobRole = selectedJobRole!.length
  const totalSelectedSearchString = selectedSearchString!.length !== 0 ? 1 : 0

  useEffect(() => {
    setAllValueFilters(
      totalSelectedFilters + totalSelectedJobRole + totalSelectedSearchString,
    )
  }, [totalSelectedFilters, totalSelectedJobRole, totalSelectedSearchString])

  const selectedBusinesUnitsValues = selectedBusinessUnits.map(bu => bu.value)

  const { data: filterData } = useGetMyStaffConfigQueryQuery(
    {
      businessUnit: selectedBusinesUnitsValues,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  )

  if (!filterData) return null

  const { actions: actionOptions, jobRoles, levels: levelOptions } = filterData

  const resetFilters = () => {
    dispatch(resetCoreTechnicalHeadFilters())
    setShouldRefetch(!shouldRefetch)
  }

  const handleSubmitFilter = () => {
    dispatch(setCoreTechnicalHeadTableConfig({ page: 1, pageSize }))
    setShouldRefetch(!shouldRefetch)
  }

  const RenderActionButtons = () => (
    <Grid item xs={12} md={3}>
      <StyledStack
        direction="row"
        spacing={isAboveSmall ? 2 : 0}
        justifyContent={isAboveSmall ? 'flex-start' : 'space-between'}
      >
        <Button
          isLoading={false}
          fullWidth={isAboveSmall}
          onClick={() => handleSubmitFilter()}
        >
          <FormattedMessage id="filters.search" />
        </Button>
        <Button
          variant="outlined"
          isLoading={false}
          fullWidth={isAboveSmall}
          onClick={() => resetFilters()}
        >
          <FormattedMessage id="filters.clear" />
        </Button>
      </StyledStack>
    </Grid>
  )

  return (
    <>
      {isAboveSmall ? (
        <StyledContainer>
          <Box role="searchbox">
            <Grid container spacing={2}>
              <LevelsActions {...{ actionOptions, levelOptions }} />
              <JobRoles {...{ jobRoles }} />
            </Grid>

            <Grid container spacing={2} sx={{ marginTop: 1 }}>
              <SearchInput />
              <RenderActionButtons />
            </Grid>
          </Box>
        </StyledContainer>
      ) : (
        <>
          <StyledBoxWrapper open={isOpen}>
            <Button onClick={() => setIsOpen(state => !state)}>
              <SettingsIcon isOpen={isOpen} />
              <FormattedMessage id="filters.label" />
              {allValueFilters > 0 && `: + ${allValueFilters}`}
            </Button>
          </StyledBoxWrapper>
          <Collapse in={isOpen}>
            <StyledMobileContainer>
              <Box role="searchbox" display="flex" flexDirection="column" gap={2}>
                <Grid container spacing={1.5}>
                  <LevelsActions {...{ actionOptions, levelOptions }} />
                  <JobRoles {...{ jobRoles }} />
                </Grid>

                <Grid container spacing={1.5}>
                  <SearchInput />
                  <RenderActionButtons />
                </Grid>
              </Box>
            </StyledMobileContainer>
          </Collapse>
        </>
      )}
    </>
  )
}

export default Filters
