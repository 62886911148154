import {
  ISearcSupportHeadQualifiedListParams,
  ISearchSupportHeadQualifiedListResponse,
} from 'interfaces'

import { evAPI } from './api'

export const supportHeadListAPI = evAPI.injectEndpoints({
  endpoints: builder => ({
    searchSupportHeadsList: builder.query<
      ISearchSupportHeadQualifiedListResponse['data'],
      ISearcSupportHeadQualifiedListParams
    >({
      query: props => {
        return {
          url: 'Management/Dashboard/SearchSupportHeadsListQuery',
          method: 'POST',
          body: { ...props },
        }
      },
      transformResponse: ({ data }: ISearchSupportHeadQualifiedListResponse) => data,
    }),
  }),
})

export const { useLazySearchSupportHeadsListQuery } = supportHeadListAPI
