import { Container } from '@mui/material'
import Body from 'components/common/Body'
import Filters from 'components/CoreTechnicalHeadList/Filters'
import SubHeader from 'components/layout/SubHeader'
import ApplicationFooter from 'components/layout/ApplicationFooter'
import BusinessUnitSelector from 'components/common/BusinessUnitSelector'
import MembersList from 'components/CoreTechnicalHeadList/MembersList'
import { ISearchMyStaffParams } from 'interfaces'
import { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import {
  selectSelectedBusinessUnits,
  selectCoreTechnicalHeadSelectedFilters,
  selectCoreTechnicalHeadSelectedJobRole,
  selecCoreTechnicalHeadSelectedSearchString,
  selectCoreTechnicalHeadTableConfig,
  useAppSelector,
  useAppDispatch,
  setCoreTechnicalHeadSearchString,
} from 'store'

import { useLazySearchCoreTechnicalHeadsListQuery } from 'store/api'
import { removeEmptyValues } from 'utils/objectHandlers'

const CoreTechnicalHeadList = () => {
  const { id } = useParams()
  const [searchParams] = useSearchParams()
  const q = searchParams.get('q')
  const dispatch = useAppDispatch()

  const [shouldRefetch, setShouldRefetch] = useState(false)
  const selectedFilters = useAppSelector(selectCoreTechnicalHeadSelectedFilters)
  const tableConfig = useAppSelector(selectCoreTechnicalHeadTableConfig)
  const selectedJobRole = useAppSelector(selectCoreTechnicalHeadSelectedJobRole)
  const selectedSearchString = useAppSelector(selecCoreTechnicalHeadSelectedSearchString)
  const selectedBusinessUnits = useAppSelector(selectSelectedBusinessUnits)

  const selectedBusinesUnitsValues = selectedBusinessUnits.map(bu => bu.value)

  const myStaffFilters: ISearchMyStaffParams = {
    ...tableConfig,
    ...selectedFilters,
    searchTerm: selectedSearchString,
    jobRoles: selectedJobRole,
    businessUnits: selectedBusinesUnitsValues,
  }

  const [getCoreTechnicalHeadList, { data: safetyAccompanyingData, isFetching }] =
    useLazySearchCoreTechnicalHeadsListQuery()

  const handleSearchCoreTechnicalHeadList = () => {
    try {
      getCoreTechnicalHeadList(removeEmptyValues(myStaffFilters))
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    handleSearchCoreTechnicalHeadList()
  }, [tableConfig, shouldRefetch])

  useEffect(() => {
    if (id || q) {
      dispatch(setCoreTechnicalHeadSearchString(q || id || ''))
      setShouldRefetch(!shouldRefetch)
    }
  }, [id, q])

  if (!safetyAccompanyingData) return null
  const { records, totalPages, totalRecords } = safetyAccompanyingData

  return (
    <>
      <Body>
        <SubHeader
          breadcrumbItems={[
            {
              text: <FormattedMessage id="app.labels.dashboard" />,
              href: '/dashboard',
            },
            {
              text: <FormattedMessage id="app.labels.coreTechnicalHeadList" />,
              href: '/view-safety-accompanying-person-list',
            },
          ]}
        >
          <BusinessUnitSelector
            setShouldRefetch={setShouldRefetch}
            shouldRefetch={shouldRefetch}
          />
        </SubHeader>
        <Filters setShouldRefetch={setShouldRefetch} shouldRefetch={shouldRefetch} />

        <Container>
          <MembersList
            isLoading={isFetching}
            records={records}
            totalPages={totalPages}
            totalRecords={totalRecords}
          />
        </Container>
      </Body>
      <ApplicationFooter />
    </>
  )
}

export default CoreTechnicalHeadList
