import { KeyboardArrowDown } from '@mui/icons-material'
import {
  Checkbox,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from '@mui/material'
import { IValueLabel } from 'interfaces'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  selectSupportHeadSelectedJobRole,
  setSupportHeadJobRole,
  useAppDispatch,
  useAppSelector,
} from 'store'

import {
  CheckedIcon,
  StyledSelectFormControl,
  UncheckedIcon,
} from 'components/StaffMembers/Filters/styles'

interface JobRolesProps {
  jobRoles?: IValueLabel[]
}

const JobRoles = ({ jobRoles }: JobRolesProps) => {
  const selectedJobRole = useAppSelector(selectSupportHeadSelectedJobRole)

  const theme = useTheme()
  const intl = useIntl()

  const dispatch = useAppDispatch()

  return (
    <Grid item xs={12} sm={12} md={3} lg={3}>
      {jobRoles && jobRoles.length && (
        <StyledSelectFormControl
          size="small"
          variant="outlined"
          fullWidth
          // ToDo - make borderWidth on focus as 1px everywhere on customTheme
          sx={{
            '& .MuiOutlinedInput-notchedOutline': {
              border: '1px solid #C4C4C4 !important',
            },
          }}
        >
          <Typography color={theme.palette.grey[800]} gutterBottom>
            <FormattedMessage id="membersList.jobRole" />
          </Typography>
          <Select
            IconComponent={KeyboardArrowDown}
            displayEmpty
            id="job-role-select"
            value={selectedJobRole || []}
            size="small"
            fullWidth
            multiple
            style={{
              backgroundColor: '#fff',
            }}
            onChange={e => {
              const { value } = e.target
              dispatch(setSupportHeadJobRole(value as string[]))
            }}
            renderValue={selected => {
              if (selected.length === 0) {
                return intl.formatMessage({
                  id: 'app.labels.select',
                })
              }
              const selectedValues = jobRoles.map(jobRole => {
                if (selected && selected.includes(jobRole.value as string)) {
                  return jobRole.label
                }
                return null
              })
              return selectedValues.filter(Boolean).join(', ')
            }}
          >
            {jobRoles.map(jobRole => (
              <MenuItem
                value={jobRole.value}
                key={jobRole.value}
                sx={{
                  gap: 0.5,
                  paddingInline: '0px',
                  paddingBlock: '2px',

                  '&.Mui-selected': {
                    backgroundColor: '#EBF1F6',
                  },
                }}
              >
                <Checkbox
                  icon={<UncheckedIcon />}
                  checkedIcon={<CheckedIcon />}
                  checked={
                    selectedJobRole &&
                    selectedJobRole.findIndex((item: string) => item === jobRole.value) >=
                      0
                  }
                  size="small"
                  disableRipple
                />
                <ListItemText
                  primary={jobRole.label}
                  primaryTypographyProps={{ variant: 'subtitle1' }}
                />
              </MenuItem>
            ))}
          </Select>
        </StyledSelectFormControl>
      )}
    </Grid>
  )
}

export default JobRoles
