import {
  ISearchCoreTechnicalHeadQualifiedListParams,
  ISearchCoreTechnicalHeadQualifiedListResponse,
} from 'interfaces'

import { evAPI } from './api'

export const coreTechnicalHeadListAPI = evAPI.injectEndpoints({
  endpoints: builder => ({
    searchCoreTechnicalHeadsList: builder.query<
      ISearchCoreTechnicalHeadQualifiedListResponse['data'],
      ISearchCoreTechnicalHeadQualifiedListParams
    >({
      query: props => {
        return {
          url: 'Management/Dashboard/SearchCoreTechnicalHeadsListQuery',
          method: 'POST',
          body: { ...props },
        }
      },
      transformResponse: ({ data }: ISearchCoreTechnicalHeadQualifiedListResponse) =>
        data,
    }),
  }),
})

export const { useLazySearchCoreTechnicalHeadsListQuery } = coreTechnicalHeadListAPI
