import React from 'react'
import {
  Box,
  ButtonBase,
  CircularProgress,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import actionRequiredIcon from 'assets/svgs/icon_ls_expired.svg'
import notCompliantOrangeIcon from 'assets/svgs/icon_not_compliant_orange.svg'
import ApplicationFooter from 'components/layout/ApplicationFooter'
import DashboardInfoDesktop from 'components/DashboardInfos/DashboardInfoDesktop'
import DashboardInfoMobile from 'components/DashboardInfos/DashboardInfoMobile'
import SubHeader from 'components/layout/SubHeader'
import Body from 'components/common/Body'
import BusinessUnitSelector from 'components/common/BusinessUnitSelector'
import hookmanIcon from 'assets/svgs/hookman icon.svg'
import { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { selectSelectedBusinessUnits, useAppSelector } from 'store'
import {
  useGetDashboardCapabilityQueryMutation,
  useGetDashboardEvLiveWorkingCapabilytyQueryMutation,
  useGetDashboardToggleServicingAvailablityCommandMutation,
} from 'store/api'
import { ValueLabelType } from 'types'
import {
  AntSwitch,
  LightTooltip,
  StyledBoxAvailable,
  StyledDashboardSideTextContainer,
  StyledDashboardWrapper,
  StyledDashboardTitle,
  StyledDashboardSubTitleeWrapper,
  StyledDashboardTitleWrapper,
  StyledDisabledTooltipContainer,
  StyledLightTooltipContainer,
  StyledLightTooltipContent,
  StyledStatusButton,
  StyledTypographyStatus,
} from 'routes/Dashboard/style'
import { Recommendations, ILevelRecommendation } from 'interfaces'
import { selectUserIsImpersonate } from 'store/slices'

const Dashboard = () => {
  const t = useIntl()
  const navigate = useNavigate()
  const [shouldRefetch, setShouldRefetch] = useState(false)
  const { palette } = useTheme()
  const isLargeScreen = useMediaQuery('(min-width: 1200px)')
  const selectedBusinessUnits = useAppSelector(selectSelectedBusinessUnits)
  const isImpersonate = useAppSelector(selectUserIsImpersonate)

  const [getConfig, { data }] = useGetDashboardCapabilityQueryMutation()
  const [getConfigCapability, { data: capabilityData }] =
    useGetDashboardEvLiveWorkingCapabilytyQueryMutation()

  useEffect(() => {
    const getConfigPayload = selectedBusinessUnits.reduce(
      (acc: string[], curr: ValueLabelType) => {
        return [...acc, curr.value]
      },
      [],
    )
    getConfig({ businessUnits: getConfigPayload })
    getConfigCapability({ businessUnits: getConfigPayload })
  }, [shouldRefetch])

  const [toggleServicing, { isLoading }] =
    useGetDashboardToggleServicingAvailablityCommandMutation()

  const handleToggleServicing = () => {
    const payload = {
      businessUnitUUID: selectedBusinessUnits[0].value,
    }
    toggleServicing(payload)
    const getConfigPayload = selectedBusinessUnits.reduce(
      (acc: string[], curr: ValueLabelType) => {
        return [...acc, curr.value]
      },
      [],
    )
    getConfig({ businessUnits: getConfigPayload })
  }

  if (!data || !capabilityData)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="90vh"
      >
        <CircularProgress size={20} />
      </Box>
    )

  const intl = (id: string) => {
    return t.formatMessage({
      id: `dashboard.evCapability.evStatus.${id}`,
    })
  }

  const getStatusLabel = () => {
    return data.compliance === 'SafetyAndService'
      ? intl('compliant')
      : intl('notCompliant')
  }
  return (
    <Body>
      <SubHeader
        breadcrumbItems={[
          {
            text: <FormattedMessage id="appNavigation.menuItem.home" />,
            href: '/',
          },
          {
            text: <FormattedMessage id="app.labels.dashboard" />,
            href: '/dashboard',
          },
        ]}
      >
        <BusinessUnitSelector
          setShouldRefetch={setShouldRefetch}
          shouldRefetch={shouldRefetch}
        />
      </SubHeader>

      <Container sx={{ px: { xs: 0, sm: 0 } }}>
        <StyledDashboardTitleWrapper>
          <StyledDashboardWrapper>
            <StyledDashboardTitle>
              <FormattedMessage id="dashboard.evCapability.section.title" />
            </StyledDashboardTitle>
            {!data.status || data.status === null ? null : (
              <StyledDashboardSideTextContainer>
                <StyledTypographyStatus compliance={data.compliance} as="div">
                  <LightTooltip
                    arrow
                    title={
                      <StyledLightTooltipContainer>
                        <Typography>{data.statusRecommendation}</Typography>

                        {data?.safetyRequirements?.length > 0 && (
                          <StyledLightTooltipContent>
                            <Typography>
                              <FormattedMessage id="dashboard.sideText.tooltip.qualifiedTechnicians.SafetyTitle" />
                            </Typography>
                            <Box>
                              {data?.safetyRequirements?.map(
                                (item: ILevelRecommendation, index: number) => (
                                  <Box key={index}>
                                    {item?.recommendations.map(
                                      (recom: Recommendations, ind: number) => (
                                        <React.Fragment key={ind}>
                                          <img
                                            src={`assets/${recom.levelIcon}`}
                                            alt={recom.levelName}
                                          />
                                          {ind < item?.recommendations?.length - 1 ? (
                                            <span>+</span>
                                          ) : null}
                                        </React.Fragment>
                                      ),
                                    )}
                                    {item?.recommendations.map(
                                      (recom: any, ind: number) => (
                                        <React.Fragment key={ind}>
                                          <Typography>{recom.levelName}</Typography>
                                          {ind < item?.recommendations?.length - 1 ? (
                                            <span>+</span>
                                          ) : null}
                                        </React.Fragment>
                                      ),
                                    )}
                                    <span>{'x'}</span>
                                    <Typography> {item.recommendedAmount}</Typography>
                                  </Box>
                                ),
                              )}
                            </Box>
                          </StyledLightTooltipContent>
                        )}
                        {data?.serviceRequirements?.length > 0 && (
                          <StyledLightTooltipContent>
                            <Typography>
                              <FormattedMessage id="dashboard.sideText.tooltip.qualifiedTechnicians.ServiceTitle" />
                            </Typography>
                            <Box>
                              {data?.serviceRequirements?.map(
                                (item: ILevelRecommendation, index: number) => (
                                  <Box key={index}>
                                    {item?.recommendations.map(
                                      (recom: Recommendations, ind: number) => (
                                        <React.Fragment key={ind}>
                                          <img
                                            src={`assets/${recom.levelIcon}`}
                                            alt={recom.levelName}
                                          />
                                          {ind < item?.recommendations?.length - 1 ? (
                                            <span>+</span>
                                          ) : null}
                                        </React.Fragment>
                                      ),
                                    )}
                                    {item?.recommendations.map(
                                      (recom: any, ind: number) => (
                                        <React.Fragment key={ind}>
                                          <Typography>{recom.levelName}</Typography>
                                          {ind < item?.recommendations?.length - 1 ? (
                                            <span>+</span>
                                          ) : null}
                                        </React.Fragment>
                                      ),
                                    )}
                                    <span>{'x'}</span>
                                    <Typography> {item.recommendedAmount}</Typography>
                                  </Box>
                                ),
                              )}
                            </Box>
                          </StyledLightTooltipContent>
                        )}
                      </StyledLightTooltipContainer>
                    }
                  >
                    <StyledDisabledTooltipContainer>
                      {data.compliance !== 'SafetyAndService' &&
                        (data.compliance === 'SafetyOnly' ? (
                          <img src={notCompliantOrangeIcon} alt="Not Compliant Icon" />
                        ) : (
                          <img src={actionRequiredIcon} alt="Action Required Icon" />
                        ))}
                      <FormattedMessage id="dashboard.evCapability.section.evStatus" />
                      <StyledStatusButton>{getStatusLabel()}</StyledStatusButton>
                    </StyledDisabledTooltipContainer>
                  </LightTooltip>
                </StyledTypographyStatus>
                {isLoading ? (
                  <CircularProgress size={20} />
                ) : (
                  <StyledBoxAvailable>
                    <Typography color={palette.grey[700]} fontSize={16}>
                      <FormattedMessage id="dashboard.evCapability.section.switch.availableForServicing" />
                    </Typography>
                    <Box display="flex">
                      <Typography color={palette.grey[500]}>
                        <FormattedMessage id="dashboard.evCapability.section.switch.availableForServicing.no" />
                      </Typography>
                      <AntSwitch
                        checked={data.isAvailableForServicing}
                        onChange={() => handleToggleServicing()}
                        disabled={isImpersonate}
                      />
                      <Typography
                        color={
                          data.isAvailableForServicing
                            ? palette.grey[600]
                            : palette.grey[500]
                        }
                      >
                        <FormattedMessage id="dashboard.evCapability.section.switch.availableForServicing.yes" />
                      </Typography>
                    </Box>
                  </StyledBoxAvailable>
                )}
              </StyledDashboardSideTextContainer>
            )}
          </StyledDashboardWrapper>

          <StyledDashboardSubTitleeWrapper>
            <ButtonBase onClick={() => navigate('/staff-members')}>
              <Typography>
                <FormattedMessage id="dashboard.evCapability.section.button.viewStaffList" />
              </Typography>
            </ButtonBase>
            <Box sx={{ display: 'flex', gap: '7px' }}>
              <img src={hookmanIcon} alt="hook man icon" width={'22px'} />
              <ButtonBase
                onClick={() => navigate('/view-safety-accompanying-person-list')}
                sx={{
                  textAlign: 'left',
                }}
              >
                <Typography>
                  <FormattedMessage id="dashboard.evCapability.section.button.viewSafetyAccompanyingPersonList" />
                </Typography>
              </ButtonBase>
            </Box>
            <ButtonBase
              onClick={() => navigate('/core-technical-head-list')}
              sx={{
                textAlign: 'left',
              }}
            >
              <Typography>
                <FormattedMessage id="dashboard.evCapability.section.button.coreTechnicalHeadsList" />
              </Typography>
            </ButtonBase>
            <ButtonBase
              onClick={() => navigate('/support-heads-list')}
              sx={{
                textAlign: 'left',
              }}
            >
              <Typography>
                <FormattedMessage id="dashboard.evCapability.section.button.supportHeadsList" />
              </Typography>
            </ButtonBase>
          </StyledDashboardSubTitleeWrapper>
        </StyledDashboardTitleWrapper>

        <Box sx={{ px: { xs: 0 } }}>
          {isLargeScreen ? <DashboardInfoDesktop /> : <DashboardInfoMobile />}
        </Box>
      </Container>
      <ApplicationFooter />
    </Body>
  )
}

export default Dashboard
