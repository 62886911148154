// Redux business units feature slice to store all global user state related data.

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISupportHeadState } from 'interfaces'
import { RootState } from 'store'

const initialState: ISupportHeadState = {
  filters: {
    certifiedLevel: [],
    workingLevel: [],
    targetLevel: [],
  },
  jobRoles: [],
  searchString: '',
  table: {
    page: 1,
    pageSize: 10,
  },
}

const supportHeadListSlice = createSlice({
  name: 'supportHeadListSlice',
  initialState: initialState,
  reducers: {
    resetSupportHeadFilters: () => initialState,
    setSupportHeadFilters(
      state,
      action: PayloadAction<{
        filter: keyof ISupportHeadState['filters']
        value: string
      }>,
    ) {
      const { filter, value } = action.payload
      const filterValue = state.filters[filter]
      if (filterValue) {
        if (filterValue.includes(value)) {
          state.filters[filter] = filterValue.filter(item => item !== value)
        } else {
          state.filters[filter] = [...filterValue, value]
        }
      } else {
        state.filters[filter] = [value]
      }
    },
    setSupportHeadJobRole(state, action: PayloadAction<string[]>) {
      state.jobRoles = action.payload
    },
    setSupportHeadSearchString(state, action: PayloadAction<string>) {
      state.searchString = action.payload
    },
    setSupportHeadTableConfig(state, action: PayloadAction<ISupportHeadState['table']>) {
      const { page, pageSize } = action.payload
      state.table.page = page
      state.table.pageSize = pageSize
    },
  },
})

export const {
  setSupportHeadFilters,
  setSupportHeadJobRole,
  setSupportHeadSearchString,
  resetSupportHeadFilters,
  setSupportHeadTableConfig,
} = supportHeadListSlice.actions

export const selectSupportHeadTableConfig = (state: RootState) => state.supportHead.table
export const selectSupportHeadSelectedFilters = (state: RootState) =>
  state.supportHead.filters
export const selectSupportHeadSelectedJobRole = (state: RootState) =>
  state.supportHead.jobRoles
export const selecSupportHeadSelectedSearchString = (state: RootState) =>
  state.supportHead.searchString

export default supportHeadListSlice.reducer
