import {
  IAcceptOrDeclineTrainingLevelProposalMutationParams,
  IApiResponse,
  IEVTrainingLevel,
  IEVTrainingLevelDetailsParams,
  IEVTrainingLevelDetailsResponse,
  IEVTrainingLevelsResponse,
  ILogBatteryRepairResponse,
  IRequestLogCertificateResponse,
  ISubmitEvTrainingRequestMutationParams,
  ITrainingLevelAgreementResponse,
  ITrainingLevelAgrementParams,
  ITrainingLevelDetails,
  ITrainingLevelDocumentParams,
  ITrainingLevelDocumentResponse,
} from 'interfaces'
import { IEVLevelsState, setEvLevels } from 'store/slices'
import { evAPI } from './api'

export const evTrainingApi = evAPI.injectEndpoints({
  endpoints: builder => ({
    getEVTrainingLevels: builder.query<IEVTrainingLevel[], string>({
      query: userUUID => `EvTraining/getEvTrainingLevelsQuery?userUUID=${userUUID}`,
      transformResponse: (response: IEVTrainingLevelsResponse) => response.data,
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const payload = (await queryFulfilled).data

        const stateObject = payload.reduce((acc, curr) => {
          acc[curr.levelUUID] = {
            levelUUID: curr.levelUUID,
            rolesAndResponsibilities: false,
            documents: false,
            modulesAndCourses: {},
          }
          return acc
        }, {} as Record<string, IEVLevelsState>)

        dispatch(setEvLevels(stateObject))
      },
      providesTags: ['EVTrainingLevels'],
    }),
    getEVTrainingLevelDetails: builder.query<
      ITrainingLevelDetails,
      IEVTrainingLevelDetailsParams
    >({
      query: ({ userUUID, levelUUID, IsManagerViewing }) => {
        const baseUrl = `EvTraining/GetEvTrainingLevelDetailsQuery?userUUID=${userUUID}&levelUUID=${levelUUID}`
        const fullUrl = IsManagerViewing ? `${baseUrl}&IsManagerViewing=true` : baseUrl
        return fullUrl
      },
      transformResponse: (response: IEVTrainingLevelDetailsResponse) => response.data,
      providesTags: ['EVTrainingDetails'],
      keepUnusedDataFor: 60 * 5,
    }),
    getEVTrainingLevelDocumentDetails: builder.query<
      ITrainingLevelDocumentResponse,
      ITrainingLevelDocumentParams
    >({
      query: ({ documentUUID }) =>
        `EvTraining/GetEvTrainingLevelDocumentQuery?documentUUID=${documentUUID}`,
    }),
    getEvTrainingLevelAgreementConfig: builder.query<
      ITrainingLevelAgreementResponse,
      ITrainingLevelAgrementParams
    >({
      query: ({ levelUUID }) =>
        `EvTraining/GetEvTrainingLevelAgreementConfigQuery?levelUUID=${levelUUID}`,
      transformResponse: (response: ITrainingLevelAgreementResponse) => response,
    }),
    submitEvTrainingRequest: builder.mutation<
      IApiResponse,
      ISubmitEvTrainingRequestMutationParams
    >({
      query: props => {
        return {
          url: 'EvTraining/SubmitEvTrainingRequestCommand',
          method: 'POST',
          body: { ...props },
        }
      },
      invalidatesTags: ['EVTrainingDetails'],
    }),
    AcceptOrDeclineTrainingLevelProposal: builder.mutation<
      IApiResponse,
      IAcceptOrDeclineTrainingLevelProposalMutationParams
    >({
      query: props => {
        return {
          url: 'EvTraining/AcceptOrDeclineTrainingLevelProposalCommand',
          method: 'POST',
          body: { ...props },
        }
      },
      invalidatesTags: ['EVTrainingLevels', 'EVTrainingDetails'],
    }),
    submitLogBatteryRepair: builder.mutation<IApiResponse, FormData>({
      query: props => {
        return {
          url: 'EvTraining/SubmitLogBatteryRepairCommand',
          method: 'POST',
          body: props,
        }
      },
      invalidatesTags: ['EVTrainingDetails'],
    }),
    requestLogCertificate: builder.query<IRequestLogCertificateResponse, string>({
      query: logUUID =>
        `EvTraining/GetLogBatteryRepairCertificateQuery?logRepairUUID=${logUUID}`,
    }),
    getLogBatteryRepairConfig: builder.query<ILogBatteryRepairResponse, void>({
      query: () => 'EvTraining/GetLogBatteryRepairConfigQuery',
    }),
  }),
})

export const {
  useGetEVTrainingLevelsQuery,
  useGetEVTrainingLevelDetailsQuery,
  useLazyGetEVTrainingLevelDetailsQuery,
  useGetEVTrainingLevelDocumentDetailsQuery,
  useGetEvTrainingLevelAgreementConfigQuery,
  useSubmitEvTrainingRequestMutation,
  useAcceptOrDeclineTrainingLevelProposalMutation,
  useSubmitLogBatteryRepairMutation,
  useLazyRequestLogCertificateQuery,
  useGetLogBatteryRepairConfigQuery,
} = evTrainingApi
