import { Box, Stack, Typography } from '@mui/material'
import { ActionRequired } from 'interfaces'
import { useNavigate } from 'react-router-dom'
import { StyledARGenericButton } from 'components/StaffMembers/ActionRequired/style'
import { FormattedMessage } from 'react-intl'

interface ConfirmExpCriteriaProps {
  handleClose: () => void
  actionRequired: ActionRequired
  userUUID: string
}

const ConfirmExpCriteria = ({
  handleClose,
  actionRequired,
  userUUID,
}: ConfirmExpCriteriaProps) => {
  const navigate = useNavigate()

  const navigateToExpCriteria = () => {
    handleClose()
    navigate(`/user-details/${userUUID}/${actionRequired.levelUUID!}?isMgr`)
  }
  return (
    <Stack spacing={2}>
      <Typography sx={{ fontSize: '14px' }}>
        <FormattedMessage id="staffMembers.actionRequired.confirmExpCriteria.text" />
      </Typography>
      <Box
        sx={{
          alignSelf: 'end',
          backgroundColor: 'green',
          width: 'fit-content',
        }}
      >
        <StyledARGenericButton onClick={() => navigateToExpCriteria()}>
          <FormattedMessage id="app.buttons.view" />
        </StyledARGenericButton>
      </Box>
    </Stack>
  )
}

export default ConfirmExpCriteria
